<script lang="jsx">
export default {
  name: 'SizeFeedBackEnter',
  functional: true,
  inject: ['language'],
  render(h, { injections, listeners }) {
    const { language } = injections
    return (
      <div class="size-feedback_title">
        {language.SHEIN_KEY_PWA_21097}
        <span v-on:click={listeners['onSizeFeedbackBtnClick']}>{language.SHEIN_KEY_PWA_21088}</span>
      </div>
    )
  },
}
</script>

<style lang="less">
.size-feedback {
  &_title {
    color: #666;
    line-height: 1;
    margin-bottom: 10px;

    span {
      color: @sui_color_link;
      cursor: pointer;
    }
  }
}
</style>
