var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productPrice-estimatedOld",style:({
    ..._vm.strategyStyle,
    ..._vm.containerStyle
  }),on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},[(_vm.complianceMode || _vm.complianceModeDe)?[_c('p',{staticClass:"productPrice-estimatedOld__font-14px"},[_vm._v("\n      "+_vm._s(_vm.price.amountWithSymbol)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"productPrice-estimatedOld__vertical",style:({
        background: _vm.strategyStyle.color})}),_vm._v(" "),_c('p',{staticClass:"productPrice-estimatedOld__font-10px productPrice-estimatedOld__text-overflow2"},[_vm._v("\n      "+_vm._s(_vm.complianceModeText)+"\n    ")])]:(!!_vm.price.buyMultiplePic)?[_c('p',{staticClass:"productPrice-estimatedOld__font-12px"},[_vm._v("\n      "+_vm._s(_vm.buyMultipleText.left)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"productPrice-estimatedOld__vertical",style:({ background: _vm.strategyStyle.color })}),_vm._v(" "),_c('p',{staticClass:"productPrice-estimatedOld__font-10px",domProps:{"innerHTML":_vm._s(_vm.buyMultipleText.right)}})]:(!_vm.price.addOnAmountWithSymbol)?[_c('i',{staticClass:"suiiconfont sui_icon_coupon_discount_16px productPrice-estimatedOld__icon"}),_vm._v(" "),_c('p',{staticClass:"productPrice-estimatedOld__price"},[_c('span',{staticClass:"productPrice-estimatedOld__font-10px"},[_vm._v(_vm._s(_vm.texts.estimatedBefore))]),_vm._v(" "),_c('span',{staticClass:"productPrice-estimatedOld__font-14px"},[_vm._v(_vm._s(_vm.price.amountWithSymbol)+" ")]),_vm._v(" "),_c('span',{staticClass:"productPrice-estimatedOld__font-10px"},[_vm._v(" "+_vm._s(_vm.texts.estimatedAfter)+" ")])])]:[_c('p',{staticClass:"productPrice-estimatedOld__font-12px"},[_vm._v("\n      "+_vm._s(_vm.price.amountWithSymbol)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"productPrice-estimatedOld__vertical",style:({
        background: _vm.strategyStyle.color
      })}),_vm._v(" "),_c('p',{staticClass:"productPrice-estimatedOld__font-10px productPrice-estimatedOld__text-overflow"},[_vm._v("\n      "+_vm._s(_vm.texts.ifTotalOrderOver)+"\n    ")])],_vm._v(" "),(_vm.countTimer.timeObj && _vm.config.isShowCountdown && !(_vm.complianceMode || _vm.complianceModeDe))?_c('div',{staticClass:"productPrice-estimatedOld__countdown-wrap"},[_vm._v("\n    "+_vm._s(_vm.language.SHEIN_KEY_PWA_25725)+" \n    "),_c('div',{staticClass:"productPrice-estimatedOld__countdown-time",domProps:{"innerHTML":_vm._s(_vm.countdownTips)}})]):_vm._e(),_vm._v(" "),(_vm.hasLink)?_c('i',{staticClass:"suiiconfont sui_icon_more_right_12px_2 productPrice-estimatedOld__icon-link"}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }