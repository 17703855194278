import {
  getBubbleInfo,
  isBaseCodePriorityComplete,
} from 'public/src/pages/goods_detail/utils/sizeInfoDesc.js'
import { template } from '@shein/common-function'
import { OriginSizes } from './utils'

class SizeCalculator {
  constructor(props) {
    this.init(props)
  }
  init(props) {
    const {
      showMultiLayer,
      language,
      skcSaleAttr,
      sizeInfoDes,
      tackInfoDes,
      skuList,
      localSizeRules,
      polyAttrSize,
      sizepriority,
    } = props

    this.showMultiLayer = showMultiLayer
    this.language = language
    this.sizeInfoDes = sizeInfoDes
    this.skcSaleAttr = skcSaleAttr
    this.skuList = skuList
    this.localSizeRules = localSizeRules
    this.polyAttrSize = polyAttrSize

    this.dataMapOrder = []
    this.dataMap = {}
    this.bubbleInfo = getBubbleInfo(skcSaleAttr, sizeInfoDes, tackInfoDes, language, sizepriority)
    this.isMatched = this.getMatchedStatus()
    this.isShowBaseCode = isBaseCodePriorityComplete(this.skcSaleAttr, this.sizeInfoDes)
  }

  getMatchedStatus() {
    if (this.polyAttrSize && this.localSizeRules.length) {
      for (const attrItem of this.polyAttrSize) {
        for (const localItem of this.localSizeRules) {
          if (attrItem.attr_value_name_en === localItem.name) {
            return true
          }
        }
      }
      return false
    } else {
      return false
    }
  }
  initSizeInfoIndex({ needSetSkuInfo, needSetSizeIndex, sizeInfoIndexBak, sizeInfoIndex, chooseCheck } = {}) {
    if (needSetSkuInfo) this.needSetSkuInfo = needSetSkuInfo
    if (needSetSizeIndex) this.needSetSizeIndex = needSetSizeIndex
    if (sizeInfoIndexBak) this.sizeInfoIndexBak = sizeInfoIndexBak
    if (sizeInfoIndex) this.sizeInfoIndex = sizeInfoIndex
    // 先初始化sizeInfoIndex, 取用户备份信息，用于color切换，再进行属性检测和覆盖
    if (this.sizeInfoIndexBak !== '{}') {
      const sizeInfoIndexBak = JSON.parse(this.sizeInfoIndexBak)
      for (let k in sizeInfoIndexBak) {
        if (!this.sizeInfoIndex?.[k]?.attr_value_id) {
          this.sizeInfoIndex[k] = sizeInfoIndexBak[k]
        }
      }
    }

    // 初始化数据
    this.skcSaleAttr.forEach((attr) => {
      if (
        !this.sizeInfoIndex[attr?.attr_id] ||
        (this.sizeInfoIndex?.[attr?.attr_id]?.attr_value_name &&
          chooseCheck(
            attr?.attr_id,
            attr?.attr_name,
            this.sizeInfoIndex[attr?.attr_id].attr_value_name,
            '',
            this.sizeInfoIndex[attr?.attr_id].attr_value_id,
          ) === undefined)
      ) {

        this.sizeInfoIndex[attr?.attr_id] = {
          attr_name: attr?.attr_name,
          attr_value_name: '',
          attr_value_id: '',
        }
      }
    })
    // 再根据skuInfo去修改选中值

    if (this.needSetSkuInfo) {
      const skuInfo = this.skuList.find((_) => _.sku_code == this.needSetSkuInfo)
      this.needSetSkuInfo = ''
      if (skuInfo) {
        skuInfo.sku_sale_attr.forEach((attr) => {
          this.sizeInfoIndex[attr?.attr_id] = {
            attr_name: attr?.attr_name,
            attr_value_name: attr?.attr_value_name,
            attr_value_id: attr?.attr_value_id,
          }
        })

        return this.sizeInfoIndex
      }
    }
    // 或者根据传递出去的externalSizeInfoIndex修改选中值
    if (Object.keys(this.needSetSizeIndex).length > 0) {
      this.skcSaleAttr.forEach((item) => {
        const subItem = item?.attr_value_list?.find(
          (_) => _.attr_value_id == this.needSetSizeIndex[item.attr_id].attr_value_id
        )
        if (subItem) {
          this.sizeInfoIndex[item?.attr_id] = {
            attr_name: item?.attr_name,
            ...subItem,
          }
        } else {
          this.sizeInfoIndex[item?.attr_id] = {
            attr_name: item?.attr_name,
            attr_value_name: '',
            attr_value_id: '',
          }
        }
      })
      this.needSetSizeIndex = {}
    }
    if (this.showMultiLayer) {
      let data = {}
      if (this.skuList.length === 1) {
        data = this.skuList[0].sku_sale_attr.reduce((prev, item) => {
          prev[item.attr_id] = item.attr_value_id
          return prev
        }, {})
      }
      for (let i = 0; i < this.skcSaleAttr.length; i++) {
        let attr = null
        if (this.skcSaleAttr[i]?.attr_value_list?.length == 1) {
          attr = this.skcSaleAttr[i].attr_value_list[0]
        } else if (this.skuList.length === 1) {
          attr = this.skcSaleAttr[i]?.attr_value_list?.find(
            (_) => _.attr_value_id == data[this.skcSaleAttr[i].attr_id]
          )
        }
        if (attr) {
          this.sizeInfoIndex[this.skcSaleAttr[i].attr_id] = {
            ...this.sizeInfoIndex[this.skcSaleAttr[i].attr_id],
            attr_name: this.skcSaleAttr[i]?.attr_name,
            ...attr,
          }
        }
      }
    }
    return this.sizeInfoIndex
  }

  getFormatSizeData({ needSetSizeIndex, needSetSkuInfo, sizeInfoIndexBak, sizeInfoIndex, chooseCheck } = {}) {

    this.initSizeInfoIndex({ needSetSizeIndex, needSetSkuInfo, sizeInfoIndexBak, sizeInfoIndex, chooseCheck })

    return {
      polyAttrSize: this.polyAttrSize,
      bubbleInfo: this.bubbleInfo,
      isMatched: this.isMatched,
      sizeInfoIndex: this.sizeInfoIndex,
      isShowBaseCode: this.isShowBaseCode,
      sizeInfoIndexBak: this.sizeInfoIndexBak,
      needSetSizeIndex: this.needSetSizeIndex
    }
  }
}

/**
 * 尺码提示计算
 */
class SizeTipsCalculator {
  constructor(props) {
    this.init(props)
  }

  init(props) {
    const {
      commentOverView,
      sizeTipsConfig,
      defaultTrueToSizeText,
      // isHitSizeTipsExperiment,
      isReady,
      constant,
      parentCats,
      language,
      showTrueToSizeStrongAbt,
      skcSaleAttr,
      polyAttrSize
    } = props
    this.constant = constant
    this.language = language
    this.parentCats = parentCats
    this.hasTargetCatId = this.getHasTargetCatIdStatus()
    // 尺码提示计算开始
    this.commentOverView = commentOverView
    this.sizeTipsConfig = sizeTipsConfig
    this.defaultTrueToSizeText = defaultTrueToSizeText
    this.isReady = isReady
    // this.isHitSizeTipsExperiment = isHitSizeTipsExperiment
    this.showTrueToSizeStrongAbt = showTrueToSizeStrongAbt
    this.skcSaleAttr = skcSaleAttr
    this.polyAttrSize = polyAttrSize
    this.feedBackBasicSizes = this.getFeedbackBasicSizes()
    this.showHightSize = this.getShowHightSizeStatus()
    this.isShowSizeTips = this.getIsShowSizeTips()
  }

  // 尺码提示计算
  getIsShowSizeTips() {
    const isLargeSizeTips = this.getIsLargeSizeTips()
    const showTrueToSizeStrongText = this.getShowTrueToSizeStrongStatus()
    const defaultData = {
      // 是否命中实验组
      // isHitExperiment: this.isHitSizeTipsExperiment,
      // 是否大码提示
      isLargeSizeTips,
      // true to size 强化文案
      showTrueToSizeStrongText,
      true_size_text: this.commentOverView?.percent_overall_fit?.true_size || '',
      showTrueToSizeStrong: !!showTrueToSizeStrongText,
      // 提示文案
      sizeTipsText: isLargeSizeTips
        ? this.language.SHEIN_KEY_PWA_23773
        : this.language.SHEIN_KEY_PWA_23774,
      // 是否展示
      isShow: false,
    }

    const isHitCatId = this.getIsHitCatId()
    const isExceptionSize = this.getIsExceptionSize()

    // 1. 是否目标品类id
    if (!isHitCatId) {
      return defaultData
    }

    // 2. 是否异常尺寸
    if (!isExceptionSize) {
      return defaultData
    }

    return {
      ...defaultData,
      isShow: true,
    }
  }

  getIsLargeSizeTips() {
    const { max, large } = this.getSizeTipsData()
    return large >= max
  }

  getIsHitCatId() {
    const { catIds = [] } = this.sizeTipsConfig || {}
    return this.checkCatId([this.parentCats], catIds)
  }

  checkCatId(arr, catIds) {
    // 无catIds配置项时提前返回
    if (!catIds.length) {
      return false
    }

    for (let item of arr) {
      if (catIds.includes(item.cat_id)) {
        return true
      }

      if (item?.children?.length) {
        return this.checkCatId(item?.children, catIds)
      }
    }

    return false
  }

  getIsExceptionSize() {
    const { max, min, large, small } = this.getSizeTipsData()

    // 存在 146 tips 或 ccc tips 文案时不展示
    if (this.defaultTrueToSizeText || !this.isReady) {
      return false
    }

    return (large >= max || small >= max) && Math.abs(large - small) >= min
  }

  getSizeTipsData() {
    const { SiteUID } = this.constant
    const { max: defaultMax, min: defaultMin, offsetSizeConfig = [] } = this.sizeTipsConfig || {}
    // 从apolloConfig中获取当前站点的最大最小值，若该站点没有配置则使用默认值
    const { max = defaultMax, min = defaultMin } = offsetSizeConfig.find(item => {
      const { SiteUID: ids } = item || {}
      return ids?.includes?.(SiteUID)
    }) || {}

    const { size_tips: sizeTips = {} } = this.commentOverView || {}
    const { large, small } = this.getOverallFitNum(sizeTips)

    return {
      max,
      min,
      large,
      small,
    }
  }

  getShowHightSizeStatus() {
    const { heightCatIds = [] } = this.sizeTipsConfig || {}
    return this.checkCatId([this.parentCats], heightCatIds)
  }
  
  getShowTrueToSizeStrongStatus() {
    const { trueToSizeStrongCatId = [] } = this.sizeTipsConfig || {}
    const true_size = this.commentOverView?.percent_overall_fit?.true_size || ''
    const comment_num = this.commentOverView?.comment_num || 0
    const true_size_num = this.getFitPercentageRoundNum(true_size)
    const hasSizeAttr =  this.skcSaleAttr?.find(_ => _.attr_id == 87)
    if(this.showTrueToSizeStrongAbt && !!hasSizeAttr && true_size_num >= 90 && comment_num >= 10 && this.checkCatId([this.parentCats], trueToSizeStrongCatId)) {
      const lanText = this.language.SHEIN_KEY_PWA_27419
      return template('', lanText)
    }else {
      return ''
    }
  }
  getFitPercentageRoundNum(text) {
    if (typeof text !== 'string' || !text) {
      return 0
    }
    const num = text.replace('%', '')
    if (isNaN(num)) {
      return 0
    }
    return Math.round(num)
  }

  getOverallFitNum(overallFit) {
    const { large = '', small = '' } = overallFit || {}
    return {
      large: this.delPercentSign(large),
      small: this.delPercentSign(small),
    }
  }

  delPercentSign(str) {
    return Number(str.replace('%', ''))
  }

  // 类目是否满足
  getHasTargetCatIdStatus() {
    // 类型条件
    return this.getTargetCatIdStatus([this.parentCats])
  }

  isTarget(cat_id) {
    const { WEB_CLIENT } = this.constant
    return (WEB_CLIENT === 'shein' ? ['1888', '2030'] : ['769', '909']).includes(cat_id)
  }

  getTargetCatIdStatus(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (this.isTarget(arr[i]?.cat_id)) return true
      if (arr[i]?.children?.length) {
        const status = this.getTargetCatIdStatus(arr[i]?.children)
        if (status) return true
      }
    }
    return false
  }

  getFeedbackBasicSizes() {
    const { polyAttrSize } = this
    if (!polyAttrSize.length) {
      return []
    }
    const one = polyAttrSize[0].attr_value_name
    for (let i = 0; i < OriginSizes.length; i++) {
      if (OriginSizes[i].includes(one)) {
        return OriginSizes[i]
      }
    }
    return []
  }

  getSizeTipsConfig() {
    return {
      hasTargetCatId: this.hasTargetCatId,
      isShowSizeTips: this.isShowSizeTips,
      showHightSize: this.showHightSize,
      feedBackBasicSizes: this.feedBackBasicSizes
    }
  }
}

export { SizeCalculator, SizeTipsCalculator }
