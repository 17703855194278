<script lang="jsx">
import SizeSelectItem from './SizeSelectItem'
import AttrPicInfo from '../AttrPicInfo.vue'

export default {
  name: 'SizeSelectList',
  inject: ['language'],
  functional: true,
  props: {
    sizeGroupPosition: {
      type: String,
      default: undefined,
    },
    skcSaleAttr: {
      type: Array,
      default: () => [],
    },
    sizeInfoIndex: {
      type: Object,
      default: () => ({}),
    },
    relatedParams: {
      type: Object,
      default: () => ({}),
    },
    localSelectParams: {
      type: Object,
      default: () => ({}),
    },
    setFoldShow: {
      type: Function,
      default: () => {},
    },
    foldBtn: {
      type: Array,
      default: () => [],
    },
    showSizeMoreHolder: {
      type: Boolean,
      default: false,
    },
    sizeRecommendContent: {
      type: Function,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    isOnSale: {
      type: Boolean,
      default: true
    },
    attrPicInfoMap: {
      type: Object,
      default: () => ({})
    },
  },
  render(h, { props = {}, listeners, injections }) {
    const { language } = injections
    const {
      skcSaleAttr = [],
      sizeInfoIndex,
      sizeGroupPosition,
      relatedParams,
      localSelectParams,
      config,
      setFoldShow,
      foldBtn,
      showSizeMoreHolder,
      sizeRecommendContent,
      isOnSale,
      attrPicInfoMap,
    } = props
    const count = skcSaleAttr.length

    const foldBtnContent = (index) =>
      foldBtn[index] ? (
        <div>
          <div
            class="goods-size__sizes-more"
            v-on:click={() => listeners['handleOpenFold'](index)}
          >
            {language.SHEIN_KEY_PWA_25732}
            <i class="suiiconfont sui_icon_more_down_12px_2"></i>
          </div>
          <div
            v-show={showSizeMoreHolder}
            class="goods-size__sizes-more-holder"
          ></div>
        </div>
      ) : null

    const itemContentEl = () =>
      skcSaleAttr.map((item, index) => (
        <div>
          {setFoldShow(index) ? (
            <SizeSelectItem
              item={item}
              key={`SizeSelectItem_${index}`}
              size-info-index={sizeInfoIndex}
              size-group-position={sizeGroupPosition}
              related-params={relatedParams}
              local-select-params={localSelectParams}
              is-on-sale={isOnSale}
              config={config}
              size-recommend-content={sizeRecommendContent}
              v-on:sizeClick={listeners['sizeClick']}
              v-on:handlerOpenAdd={listeners['handlerOpenAdd']}
              v-on:clickLocalTextSelect={listeners['clickLocalTextSelect']}
              v-on:handleSizeRecommendType={listeners['handleSizeRecommendType']}
              v-on:openSizeGuide={listeners['openSizeGuide']}
            />
          ) : null}
          {foldBtnContent(index)}
          {/* 尺码图片 */}
          {attrPicInfoMap ?
            <AttrPicInfo
              show={!!attrPicInfoMap[item.attr_id]}
              picInfo={attrPicInfoMap[item.attr_id]}
              style={ !!attrPicInfoMap[item.attr_id] ? 'margin-bottom:12px;' : undefined }
            /> : null
          }
        </div>
      ))
    return (
      <div
        class="goods-size__wrapper"
        data-count={count}
      >
        {itemContentEl()}
      </div>
    )
  },
}
</script>
