<template>
  <div 
    class="productPrice-estimatedPercent"
    :class="{ 'productPrice-estimatedPercent_percentTypeStyleWrap': isWrapStyle }"
    :style="{
      background: strategyStyle.mainBackground,
      color: strategyStyle.mainColor
    }"
    @click.stop="handleClick"
  >
    <div class="productPrice-estimatedPercent__container">
      <div class="productPrice-estimatedPercent__estimated">
        {{ texts.estimatedBefore }}
        <div 
          v-show="!isWrapStyle"
          class="productPrice-estimatedPercent__left-arrow"
          :class="{'productPrice-estimatedPercent__left-arrow_cssRight': cssRight}"
        >
          <svg
            width="5px"
            height="5px"
            viewBox="0 0 5 5"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="页面-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <path
                id="形状结合"
                d="M5,5 L0,5 L0.216889622,4.99538049 C2.87769329,4.88181811 5,2.6887547 5,-2.22044605e-16 L5,5 Z"
                :fill="strategyStyle.mainBackground"
              />
            </g>
          </svg>
        </div>
        <div
          v-show="isWrapStyle"
          class="productPrice-estimatedPercent__top-arrow"
          :style="{
            background: strategyStyle.mainBackground,
          }"
        ></div>
      </div>
      <div 
        class="productPrice-estimatedPercent__percent"
        :style="{
          background: strategyStyle.percentBackground,
          color: strategyStyle.percentColor,
          'font-weight': strategyStyle.percentFontWeight
        }"
      >
        {{ cssRight ? '' : '-' }}{{ price.discountPercent }}{{ cssRight ? '-' : '' }}
      </div>
    </div>
  </div>
</template>

<script>

// 四种样式
// 常规(同特殊闪购)、付费会员、腰带Old、腰带New、品牌闪购腰带
const ESTIMATED_TAG_NEW1_STYLE = {
  normal: {
    mainColor: '#FA6338',
    mainBackground: '#FFECE9',
    percentColor: '#FA6338',
    percentBackground: '#FFD9CE',
    percentFontWeight: '700'
  },
  paidVip: {
    mainColor: '#873C00',
    mainBackground: '#FFECE9',
    percentColor: '#873C00',
    percentBackground: '#FFD9CE',
    percentFontWeight: '700'
  },
  beltType1: {
    mainColor: '#FA6338',
    mainBackground: '#FFECE9',
    percentColor: '#FFF',
    percentBackground: '#FA6338',
    percentFontWeight: '700'
  },
  beltTypeBrand: {
    mainColor: '#FF3D00',
    mainBackground: '#FFE3D9',
    percentColor: '#FF3D00',
    percentBackground: '#FFF',
    percentFontWeight: '700'
  },
  quickAddBelt: {
    mainColor: '#FF3D00',
    mainBackground: '#FFF',
    percentColor: '#FFF',
    percentBackground: '#F54811',
    percentFontWeight: '700'
  }
}
export default {
  name: 'EstimatedTagNew1',
  props: {
    price: {
      type: Object,
      default: () => ({
        // 到手价
        amountWithSymbol: '',
        // 门槛价
        addOnAmountWithSymbol: '',
        // 折扣类型到手价
        discountPercent: '',
        usdAmount: ''
      })
    },
    config: {
      type: Object,
      default: () => ({
        isPaidUser: false,
      })
    },
    // 折扣类型到手价标签换行了
    isPercentTypeStyleWrap: {
      type: Boolean,
      default: false
    },
    texts: {
      type: Object,
      default: () => ({})
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    isBeltType: {
      type: Boolean,
      default: false
    },
    isBeltTypeBrand: {
      type: Boolean,
      default: false
    },
    isQuickAddBelt: {
      type: Boolean,
      default: false
    },
    isFloorType: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    strategyStyle() {
      let { config, isBeltType, isBeltTypeBrand, isQuickAddBelt } = this
      let { normal, paidVip, beltType1, beltTypeBrand, quickAddBelt } = ESTIMATED_TAG_NEW1_STYLE
      if (isQuickAddBelt) return quickAddBelt
      if (isBeltTypeBrand) return beltTypeBrand
      if (isBeltType) return beltType1
      if (config.isPaidUser) return paidVip
      return normal
    },
    isWrapStyle() {
      if (this.isFloorType) return false
      return this.isPercentTypeStyleWrap
    }
  },
  methods: {
    handleClick () {
      this.$emit('handleClick')
    },
  },
}

</script>

<style lang="less">
.productPrice-estimatedPercent {
  .font-dpr(20px);
  line-height: 1;
  height: 16px;
  border-radius: 2px 2px 2px 0;
  &_percentTypeStyleWrap {
    border-radius: 2px;
  }
  &__container {
    display: flex;
  }
  &__estimated {
    height: 16px;
    line-height: 12px;
    padding: 0 3px;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 2px;
  }
  &__percent {
    color: #fff;
    padding: 1px 2px 1px;
    height: 16px;
    display: flex;
    align-items: center;
    border-radius: 0px 2px 2px 0;
  }
  &__left-arrow {
    position: absolute;
    left: -5px;
    bottom: 0;
    width: 5px;
    height: 5px;
    & > svg {
      display: block;
    }
  }
  &__left-arrow_cssRight {
    transform: scalex(-1);
  }
  
  &__top-arrow {
    position: absolute;
    width: 9px;
    height: 5px;
    top: -3px;
    left: calc(50% + 9px);
    clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  }
}
</style>
