<template>
  <div 
    class="productPrice-estimatedOld"
    :style="{
      ...strategyStyle,
      ...containerStyle
    }"
    @click.stop="handleClick"
  >
    <!-- 合规划线价到手价 -->
    <template v-if="complianceMode || complianceModeDe">
      <p class="productPrice-estimatedOld__font-14px">
        {{ price.amountWithSymbol }}
      </p>
      <!-- 竖线 -->
      <div
        class=" productPrice-estimatedOld__vertical"
        :style="{
          background: strategyStyle.color}"
      ></div>
      <p class="productPrice-estimatedOld__font-10px productPrice-estimatedOld__text-overflow2">
        {{ complianceModeText }}
      </p>
    </template>
    <!-- N件到手价 -->
    <template v-else-if="!!price.buyMultiplePic">
      <p
        class="productPrice-estimatedOld__font-12px"
      >
        {{ buyMultipleText.left }}
      </p>
      <div
        class=" productPrice-estimatedOld__vertical"
        :style="{ background: strategyStyle.color }"
      ></div>
      <p
        class="productPrice-estimatedOld__font-10px"
        v-html="buyMultipleText.right"
      >
      </p>
    </template>
    <!-- 满足到手价 -->
    <template v-else-if="!price.addOnAmountWithSymbol">
      <i class="suiiconfont sui_icon_coupon_discount_16px productPrice-estimatedOld__icon"></i>
      <p class="productPrice-estimatedOld__price">
        <span class="productPrice-estimatedOld__font-10px">{{ texts.estimatedBefore }}</span>
        <span class="productPrice-estimatedOld__font-14px">{{ price.amountWithSymbol }} </span>
        <span class="productPrice-estimatedOld__font-10px"> {{ texts.estimatedAfter }} </span>
      </p>
    </template>
    <!-- 凑单到手价 -->
    <template v-else>
      <p class="productPrice-estimatedOld__font-12px">
        {{ price.amountWithSymbol }}
      </p>
      <!-- 竖线 -->
      <div
        class=" productPrice-estimatedOld__vertical"
        :style="{
          background: strategyStyle.color
        }"
      ></div>
      <p class="productPrice-estimatedOld__font-10px productPrice-estimatedOld__text-overflow">
        {{ texts.ifTotalOrderOver }}
      </p>
    </template>
    <div 
      v-if="countTimer.timeObj && config.isShowCountdown && !(complianceMode || complianceModeDe)"
      class="productPrice-estimatedOld__countdown-wrap"
    >
      {{ language.SHEIN_KEY_PWA_25725 }} 
      <div
        class="productPrice-estimatedOld__countdown-time"
        v-html="countdownTips"
      ></div>
    </div>
    <i
      v-if="hasLink"
      class="suiiconfont sui_icon_more_right_12px_2 productPrice-estimatedOld__icon-link"
    ></i>
  </div>
</template>

<script>
import { template } from '@shein/common-function'

// 常规、加车普通：粉底红字
// 特殊闪购、闪购腰带v1、加车闪购：红底白字
// 品牌闪购：白底红字
// 悬浮：白底红字

const ESTIMATED_TAG_OLD_STYLE = {
  normal: {
    color: '#FA6338',
    background: '#FFF4F1'
  },
  darkRed: {
    color: '#FFF',
    background: '#FA6338'
  },
  whiteRed: {
    color: '#FA6338',
    background: '#FFF'
  },
  normalVip: {
    color: '#873C00',
    background: '#FFECE9'
  },
  normalFlash: {
    color: '#FA6338',
    background: 'rgba(255, 255, 255, 0.87)'
  },
  vipNormalFlash: {
    color: '#873C00',
    background: 'rgba(255, 255, 255, 0.87)'
  },
  quickAddBeltNormalFlash: {
    color: '#000000',
    background: '#FFF0B0'
  }
}

export default {
  name: 'EstimatedTagOld',
  props: {
    config: {
      type: Object,
      default: () => ({
        isPaidUser: false,
      })
    },
    price: {
      type: Object,
      default: () => ({
        // 到手价
        amountWithSymbol: '',
        // 门槛价
        addOnAmountWithSymbol: '',
        // 折扣类型到手价
        discountPercent: '',
        usdAmount: ''
      })
    },
    texts: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    countTimer: {
      type: Object,
      default: () => ({
        timeObj: ''
      })
    },
    countdownTips: {
      type: String,
      default: ''
    },
    isBeltType: {
      type: Boolean,
      default: false
    },
    isBeltTypeBrand: {
      type: Boolean,
      default: false
    },
    isQuickAddBelt: {
      type: Boolean,
      default: false
    },
    complianceMode: {
      type: Boolean,
      default: false
    },
    complianceModeDe: {
      type: Boolean,
      default: false
    },
    hasLink: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    strategyStyle() {
      let { isBeltType, config, isBeltTypeBrand, isQuickAddBelt } = this
      let { normal, darkRed, whiteRed, vipNormalFlash, normalFlash, normalVip, quickAddBeltNormalFlash } = ESTIMATED_TAG_OLD_STYLE
      if (isQuickAddBelt) {
        if (isBeltTypeBrand) return darkRed
        return quickAddBeltNormalFlash
      }
      if (isBeltTypeBrand) return whiteRed
      if (isBeltType) {
        if (config.isSpecialFlash) return darkRed
        if (config.isPaidUser) return vipNormalFlash
        return normalFlash
      }
      if (config.isSpecialFlash) return darkRed
      if (config.isPaidUser) return normalVip
      return normal
    },
    complianceModeText() {
      if (this.price.addOnAmountWithSymbol) {
        return this.texts.ifTotalOrderOver
      } else {
        return this.language.SHEIN_KEY_PWA_30800
      }
    },
    buyMultipleText() {
      let left = template(this.price.amountWithSymbol, this.language.SHEIN_KEY_PWA_31767 || '{0}/Pc')
      let right = template(
        `<span style="font-weight: 700">${template(this.price.buyMultiplePic, this.language.SHEIN_KEY_PWA_31915 || '{0}Prc')}</span>`,
        this.language.SHEIN_KEY_PWA_31914 || 'With {0} purchase'
      )
      return {
        left,
        right
      }
    },
    containerStyle() {
      let result = {
        height: '.6133rem'
      }
      if (!(this.complianceMode || this.complianceModeDe) && this.price.addOnAmountWithSymbol) {
        result.height = '0.48rem'
      }
      return result
    }
  },
  methods: {
    handleClick() {
      this.$emit('handleClick')
    }
  }
}

</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.productPrice-estimatedOld {
  display: flex;
  align-items: center;
  height: .6133rem;
  padding: 0 .16rem;
  border-radius: 2px;
  max-width: 100%;
  &__price {
    margin-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    line-height: normal;
    & > span {
      vertical-align: middle;
    }
  }
  &__icon-link {
    margin-left: 2px;
  }
  &__vertical {
    width: 1px;
    height: .32rem;
    opacity: .5;
    margin: 0 .1067rem;
  }
  &__font-10px {
    .font-dpr(20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__text-overflow {
    max-width: 6.9333rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__text-overflow2 {
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
    white-space: normal;
    line-height: 9px;
  }
  &__font-14px {
    .font-dpr(28px);
    font-weight: 700;
    line-height: 17px;
    flex: 0 0 auto;
  }
  &__font-12px {
    .font-dpr(24px);
    font-weight: 700;
    line-height: 18px;
    flex: 0 0 auto;
  }
  &__countdown-wrap{
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-left: 12px;
  }
  &__countdown-time{
      display: flex;
      align-items: center;
      margin-left: 2px;
      span {
        .font-dpr(20px);
        width: 0.427rem;
        height: 0.4rem;
        line-height: 0.4rem;
        color: #FA6338;
        background-color: #fff;
        text-align: center;
        margin: 0 2px;
        border-radius: 2px;
      }
    }
}
</style>
