<script lang="jsx">
/**
 * @props
 *  @param { Number } label 当前 label
 *  @param { Number } index 当前 label 索引
 *  @param { Number } attrId 当前主属性 ID
 *  @param { String } attrName 当前主属性名称
 */
export default {
  name: 'SizeSelectLabel',
  functional: true,
  inject: ['calcSize', 'chooseCheck', 'dataMapOrder', 'constant'],
  props: {
    label: {
      type: Object,
      default: () => ({}),
    },
    attrId: {
      type: Number,
      default: 0,
    },
    attrName: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    sizeInfoIndex: {
      type: Object,
      default: () => ({}),
    },
    isOnSale: {
      type: Boolean,
      default: true
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  render(h, { props, injections, listeners }) {
    const { calcSize, chooseCheck, constant } = injections
    const { label, index, attrId, attrName, sizeInfoIndex, config, isOnSale } = props
    const { cssRight = false } = constant

    const chooseCheckStatus = chooseCheck(attrId, attrName, label.attr_value_name, '', label.attr_value_id)
    const isActiveOption = sizeInfoIndex[attrId]?.attr_value_id == label.attr_value_id
    const isActive =
    isActiveOption &&
      isOnSale &&
      chooseCheckStatus > 0

    const isSizeSoldOut =
    isActiveOption &&
      (!isOnSale || chooseCheckStatus === 0)

    const isSoldOut = !isOnSale || chooseCheckStatus === 0
    const disable = chooseCheckStatus === undefined

    const ariaItem = label.attr_std_value
      ? cssRight
        ? label.attr_value_name + ' / ' + label.attr_std_value
        : label.attr_std_value
      : label.attr_value_name

    const style = { order: index + 1 }
    const labelText = calcSize(label)

    return (
      <li
        {...{ directives: [{ name: 'ada', value: { level: 1, pos: index } }] }}
        class={[
          'goods-size__sizes-item',
          { 'size-active': isActive },
          { 'size-sold-out': isSizeSoldOut },
          { 'sold-out': isSoldOut },
          { 'size-disabled': disable },
          { 'twoline-text-box': config?.longSizeTowLine },
        ]}
        style={style}
        data-attr_value={label.attr_value_name}
        data-attr_value_id={label.attr_value_id}
        type="text"
        aria-item={ariaItem}
        v-on:click={() =>
          listeners['sizeClick']({ attr_id: attrId, attr_name: attrName }, label, true)
        }
      >
        <p class={[
          'goods-size__sizes-item-text',
          { 'goods-size__sizes-item-text--one': !config?.longSizeTowLine },
          { 'goods-size__sizes-item-text--two': config?.longSizeTowLine }
        ]}>{labelText}</p>
      </li>
    )
  }
}
</script>
