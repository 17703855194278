<script lang="jsx">
import SizeClickToBuy from './components/UI/SizeClickToBuy.vue'
import SizeGroup from './components/UI/SizeGroup'
import SizeSelectList from './components/UI/SizeSelectList.vue'
import SizeTipsContent from './components/UI/SizeTipsContent.vue'
import SizeFeedBackEnter from './components/UI/SizeFeedBackEnter.vue'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'
import ClientOnly from 'vue-client-only'
import { SizeCalculator, SizeTipsCalculator } from './sizeComputed.js'
import { template, debounce } from '@shein/common-function'
import { sortMainSubSize } from 'public/src/pages/goods_detail/utils/sizeInfoDesc.js'

/**
 * @props
 *  @param { Object }    customerStyle 个性化配置
 *  @param { Object }    config 配置项
 *    @param { Boolean }   openAutoCheckMall 开启自动切换有货mall仓库
 *    @param { String }    showSizeGroupPosition 是否展示SizeGroup的位置
 *    @param { Boolean }   hideOneSize 隐藏 oneSize
 *    @param { Boolean }   isNewSizeLocal 是否展示新的尺码
 *    @param { Boolean }   showHightSize 是否展示身高
 *    @param { Boolean }   showNewSizeGuide 是否显示新SizeGuide
 *    @param { Boolean }   showMallSite 是否显示 多mall showQuickShip
 *    @param { Boolean }   showQuickShip 是否显示 quickShip
 *    @param { Object }    hideSoldOutTips 是否隐藏售罄提示
 *    @param { Object }    showSizeRecommend 是否展示尺码推荐
 *    @param { String }    from 调用方
 *    @param { Object }    qty 数量选择器配置
 *    @param { Boolean }   isShowAttrPicInfo 是否展示尺码图片
 *    @param { Boolean }   hideRelatedSize 是否展示关联尺码属性入口（大小码）
 *  =============================================================
 *  @param { Object }  baseInfo 商品基础信息
 *  @param { Object }  saleAttrList 当前销售属性处理集合 { polyAttrSize, skcName, skcSaleAttr, skuList }
 *  @param { Object }  saleAttrGroups 销售属性Group 暂时是尺码 大小码 相关分类 [{}]（这里是写死的数据配置，2024-03-26已弃用）// TODO 数据治理
 *  @param { Object }  cccAttrGroups ccc链路传入的销售属性Group
 *  @param { Array }   cccOnlyRelatedGoods sizegroup剔除主商品的关联商品
 *  @param { String }  country 当前国家
 *  @param { String }  sizeUnit 当前尺码单位
 *  @param { Object }  skuMapCompose sku 映射聚合
 *     @param { Object } skuMap sku 映射表
 *     @param { Object } skuIdMap sku 映射表 id
 *     @param { Object } skuAllInfo sku 信息映射（实时数据）
 *  @param { Object }  dataMapCompose 完整尺码映射聚合
 *     @param { Object } dataMap 完整尺码库存映射（名字可优化了）
 *     @param { Array }  dataMapOrder 完整尺码库存排序（名字可优化了）？
 *  @param { Object }  relatedLocalSize 关联尺码数据
 *  @param { Object }  heightSizeMap 身高尺码映射关系 { cm: {}, inch: {} }
 *  @param { Object }  language 多语言信息
 *  @param { Object }  localSize 当前商品本地尺码
 *  @param { Object }  sizeInfoDes 当前商品推荐尺码表
 *  @param { Boolean } isOnSale 是否可售状态
 *  @param { Boolean } isSoldOut 是否售罄
 *  @param { String }  mallCode 当前 mallCode
 *  @param { Object }  parentCats 父级类目集合
 *  @param { Object } externalSizeInfoIndex 初始化传入选中的sizeInfoIndex, 优先级更高
 *  @param { String } externalSkuCode 初始化传入选中的sku，只在初始化时执行，和externalSizeInfoIndex二选一传入
 *  @param { Object } tipsCombo 尺码提示需要的数据
 *  @param { Array }  tackInfoDes 家居品类额外描述
 *  @param { Object}  recommendCombo 推荐聚合数据
 *  @param { Function } sizesReadyCallback sizes组件挂载完成回调
 */
export default {
  name: 'CommonSize',
  components: {
    Mall: prefetchResource.importAsyncComponent({
      chunkName: 'SizeMall',
      componentFactory: () => import(/* webpackChunkName: "SizeMall" */ './components/Mall.vue'),
    }),
    QuickShip: prefetchResource.importAsyncComponent({
      chunkName: 'CommonSize',
      componentFactory: () =>
        import(/* webpackChunkName: "QuickShip" */ './components/QuickShip.vue'),
    }),
    SizeFeedBack: prefetchResource.importAsyncComponent({
      chunkName: 'CommonSize',
      componentFactory: () =>
        import(/* webpackChunkName: "CommonSize" */ './components/SizeFeedBack'),
    }),
    SizeSlideTips: prefetchResource.importAsyncComponent({
      chunkName: 'CommonSize',
      componentFactory: () =>
        import(/* webpackChunkName: "CommonSize" */ './components/UI/SizeSlideTips.vue'),
    }),
    RecommendEntrance: prefetchResource.importAsyncComponent({
      chunkName: 'RecommendEntrance',
      componentFactory: () =>
        import(
          /* webpackChunkName: "RecommendEntrance" */ 'public/src/pages/goods_detail/top/Sizes/RecommendEntrance.vue'
        ),
    }),
  },
  props: {
    customerStyle: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    constant: {
      type: Object,
      default: () => ({}),
    },
    baseInfo: {
      type: Object,
      default: () => ({}),
    },
    // saleAttrGroups: {
    //   type: Array,
    //   default: () => ([]),
    // },
    sizeGuidPlanA: {
      type: Boolean,
      default: false,
    },
    cccAttrGroups: {
      type: Array,
      default: () => ([]),
    },
    cccOnlyRelatedGoods: {
      type: Array,
      default: () => ([]),
    },
    saleAttrList: {
      type: Object,
      default: () => ({}),
    },
    country: {
      type: String,
      default: '',
    },
    sizeUnit: {
      type: String,
      default: '',
    },
    dataMapCompose: {
      type: Object,
      default: () => ({
        dataMap: {},
        dataIdMap: null,
        dataMapOrder: [],
      }),
    },
    skuMapCompose: {
      type: Object,
      default: () => ({
        skuMap: {},
        skuIdMap: null,
        skuAllInfo: {},
      }),
    },
    heightSizeMap: {
      type: Object,
      default: () => ({ cm: {}, inch: {} }),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    sizeInfoDes: {
      type: Object,
      default: () => ({}),
    },
    isOnSale: {
      type: Boolean,
      default: true,
    },
    isSoldOut: {
      type: Boolean,
      default: false,
    },
    localSize: {
      type: Object,
      default: () => ({}),
    },
    relatedLocalSize: {
      type: Object,
      default: () => ({}),
    },
    mallCode: {
      type: String,
      default: '',
    },
    parentCats: {
      type: Object,
      default: () => ({}),
    },
    externalSizeInfoIndex: {
      type: Object,
      default: () => ({}),
    },
    externalSkuCode: {
      type: String,
      default: '',
    },
    tipsCombo: {
      type: Object,
      default: () => ({}),
    },
    recommendCombo: {
      type: Object,
      default: () => ({}),
    },
    tackInfoDes: {
      type: Array,
      default: () => [],
    },
    isOneSize: {
      type: Boolean,
      default: false
    },
    needUpdateSize: {
      type: Boolean,
      default: false
    },
    sizesReadyCallback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      feedBackCountry: '',
      skuInfo: {},
      sizeRecommendType: 0,
      // needSetSkuInfo: '',
      sizeInfoIndex: {},
      sizeWorkers: null,
      sizeInfoIndexBak: '{}', // 用户尺码选择的备份信息
      needSetSizeIndex: {},
      isInit: false,
      bubbleInfo: {},
      // showLocalSize: false,
      isShowSizeTips: {},
      lazyTipsShow: false,
      showHightSize: false,
      sizeTarget: null, // 尺码选中目标
      sizeCalculator: null,
      hasTargetCatId: false,
      feedBackBasicSizes: [],

      // 尺码折叠
      foldBtn: [],
      foldIndex: -1,
      showSizeMoreHolder: false,

      // 弹窗异步
      popContentReady: false,

      popCenter: {
        isLoad: false,
        FeedBack: false,
        LocalSize: false,
        SizeSlide: false,
      },

      isLazyLoaded: false,

      emitSizeParam: null, // 用户在 real time 数据回来前点击了 size ，需要在 real time 回来之后更新

      SelectLocalSize: {},
    }
  },
  computed: {
    goodsId() {
      return this.baseInfo?.goods_id || ''
    },
    hasOneOptionAttr() {
      const { skcSaleAttr } = this
      return !!skcSaleAttr?.find?.((_) => _.attr_value_list?.length == 1)
    },
    feedBackLocalCountry() {
      // 不联动的时候只更新本地尺码反馈
      return this.config?.isNewSizeLocal ? this.country : this.feedBackCountry
    },
    hideOneSizeModule() {
      return this.config?.hideOneSize && this.isOnlyOneSize
    },
    // 只有size属性且为one-size
    isOnlyOneSize() {
      return this.skcSaleAttr?.length === 1 && this.polyAttrSize?.length == 1 && this.polyAttrSize[0]['attr_value_name_en'] == 'one-size'
    },
    soldoutTipsLimitNum () {
      return this.tipsCombo?.soldoutTipsLimitNum  || 5
    },
    skuList() {
      return this.saleAttrList.skuList || []
    },
    skcSaleAttr() {
      return this.saleAttrList.skcSaleAttr || []
    },
    polyAttrSize() {
      return this.saleAttrList.polyAttrSize || []
    },
    dataMapOrder() {
      return this.dataMapCompose.dataMapOrder
    },
    dataMap() {
      return this.dataMapCompose.dataMap
    },
    dataIdMap() {
      return this.dataMapCompose.dataIdMap
    },
    openIdMap() {
      return !!(typeof gbCommonInfo !== 'undefined' && gbCommonInfo?.OPEN_DETAIL_SIZE_IDMAP && this.dataIdMap && this.skuIdMap)
    },
    skuMap() {
      return this.skuMapCompose?.skuMap || {}
    },
    skuIdMap() {
      return this.skuMapCompose?.skuIdMap
    },
    skuAllInfo() {
      return this.skuMapCompose?.skuAllInfo || {}
    },
    mallInfo() {
      const { mall_info = [] } = this.baseInfo
      return mall_info
    },
    showMultiLayer() {
      return !!this.config?.showMultiLayer
    },
    sizeInfoStrFilterField() {
      return [
        'size',
        'attr_id',
        'attr_name',
        'attr_value_id',
        'attr_value_name',
        'attr_value_name_en',
      ]
    },
    clickToBuyText() {
      if (this.skuInfo?.sku_code) {
        let str = ''
        const attrId = this.bubbleInfo.sizeInfoDesAttrId || 87
        this.skcSaleAttr.forEach((item) => {
          const skuLev = this.skuInfo.sku_sale_attr.find((_) => _.attr_id == item.attr_id) || {}
          let val = skuLev.attr_value_name
          if (skuLev.attr_id == attrId) {
            const item_another =
              item.attr_value_list.find((_) => _.attr_value_id == skuLev.attr_value_id) || {}
            if (item_another.attr_std_value) {
              val = item_another.attr_std_value + ' / ' + val
            }
            if (item_another.correspond) {
              val += `(${item_another.correspond})`
            }
          }
          val = val.length > 16 ? val.slice(0, 15) + '...' : val
          str += ' / ' + val
        })
        return str.slice(3)
      }
      return this.language.SHEIN_KEY_PWA_19184 || 'Click to buy'
    },
    onlyOneSize() {
      // 只有一个尺码需默认选中
      return (this.skcSaleAttr.length == 1 && this.polyAttrSize.length <= 1) || this.skuList?.length == 1
    },
    // state
    active() {
      return this.skuInfo?.sku_code
    },
    localSizeRules() {
      const localSizeRules = this.localSize?.size_rule_list || {}
      return localSizeRules[this.country] || []
    },
    localSizeMap() {
      const localSizeMap = {}
      if (!this.localSizeRules.length) return {}
      this.localSizeRules.forEach((local) => {
        localSizeMap[local.name] = local.correspond
      })
      return localSizeMap
    },
    plusSizeRuleList() {
      return this.relatedLocalSize?.plusSizeRule?.[this.country] || []
    },
    relatedSizeMap() {
      const relatedSizeMap = {}
      if (!this.plusSizeRuleList.length) return {}
      this.plusSizeRuleList.forEach((local) => {
        relatedSizeMap[local.name] = local.correspond
      })
      return relatedSizeMap
    },
    // 尺码信息部分 ⬇️
    showDesc() {
      // if (!this.skuInfo.sku_code) return false
      const showDesc = !!(
        this.sizeInfoDesIndex != -1 &&
        (this.sizeInfoStr?.length || this.sizeStockTips)
      )

      if (showDesc) this.sizeScrollView()
      return showDesc
    },
    showSoldOutTips() {
      // oneSize隐藏的时候库存提示外露
      return this.sizeStockTips && (this.showDesc ? this.hideOneSizeModule : true) && !this.config?.hideSoldOutTips
    },
    sizeStockTips() {
      if (this.config.getSkcEstimatedInfo?.isSatisfiedBuyMultiple) return false
      if (this.config.qtySelectShow && this.config.getEstimatedInfo?.isSatisfiedBuyMultiple) return false
      let judge
      if (this.skuInfo.sku_code) {
        const mall = this.skuInfo.mall[this.mallCode]
        judge = mall?.stock > 0 && mall?.stock <= this.soldoutTipsLimitNum
      } else {
        judge = !this.isAllSoldOut && this.almostSoldOut
      }
      return judge && !this.config?.qty?.isShow
    },
    // 所有sku库存紧张
    almostSoldOut() {
      let allSkuStock = 0
      for (let i = 0; i < this.skuList.length; i++) {
        allSkuStock += +this.skuList[i]?.mall?.[this.mallCode]?.stock || 0
        if (allSkuStock > this.soldoutTipsLimitNum) {
          return false
        }
      }
      if (allSkuStock == 0) {
        return false
      }
      return true
    },
    // 当前 Mall 下 sku 售罄
    currentSkuSoldOut() {
      const { skuInfo } = this
      if (!skuInfo.sku_code) return false
      const { mall_stock = [] } = skuInfo
      return mall_stock?.every(item => !item.stock)
    },
    //是否下架和售罄
    isAllSoldOut() {
      return !this.isOnSale || this.isSoldOut
    },
    sizeInfoTitle() {
      return this.bubbleInfo.title || ''
    },
    // hurry 提示
    soldOutTips() {
      if (this.skuInfo.sku_code) {
        return this.changeTips(this.skuInfo.mall[this.mallCode]?.stock)
      } else {
        return this.language.SHEIN_KEY_PWA_16827
      }
    },
    // 尺码点击后会更新
    sizeInfoDesIndex() {
      if (!this.isInit) return -1
      const { sizeTarget = null } = this
      const { sizeInfoDesAttrList } = this.bubbleInfo
      const index = sizeInfoDesAttrList.findIndex((_) => _.attr_value_name == sizeTarget)

      return index
    },
    // 尺码描述
    sizeInfoStr() {
      // if (!this.skuInfo.sku_code) return []
      const { isShowBaseCode, sizeUnit } = this
      let info = []
      const result = []
      if (this.sizeInfoDesIndex != -1) {
        info = this.bubbleInfo?.[sizeUnit] || []
      }

      info.forEach((_, i) => {
        // 当前选中size
        const item = _.list[this.sizeInfoDesIndex]
        result.push({
          name: _.name,
          list: [],
        })
        for (const k in item) {
          if (this.sizeInfoStrFilterField.includes(k)) continue
          const val = item[k].replace(/(cm|inch)/g, '').trim()
          if (val && val !== '/') {
            result[i].list.push({ k: k.trim(), val: item[k].trim() })
          }
        }
      })

      // 基码支持排序
      if (isShowBaseCode) {
        let baseSizeSortMap = this.sizeInfoDes.basicAttribute.base_attr_sort || null
        if (baseSizeSortMap) {
          for (let item of result) {
            item.list = item.list.sort((a, b) => baseSizeSortMap[a.k] - baseSizeSortMap[b.k])
          }
        }
      }

      return result.filter((_) => _.list.length)
    },
    sizeGroupPosition() {
      const showSizeGroupPosition = this.config?.showSizeGroupPosition
      // 关联商品不包含主商品
      if(showSizeGroupPosition === 'fold') return this.cccOnlyRelatedGoods.length ? showSizeGroupPosition : ''
      const curSizeGroupIndex = this.cccAttrGroups?.findIndex((i) => i.isCurrentGroup)
      if(this.cccAttrGroups.length < 2 || !showSizeGroupPosition || curSizeGroupIndex === -1) return void 0
      return showSizeGroupPosition
    },
    // 显示 size guide 入口
    showSlideSizeGuide() {
      const { sizeInfoDesIndex, config } = this
      return sizeInfoDesIndex != -1 && !config?.showNewSizeGuide
    },
    showSizeAttributeEntrance () {
      return  this.config.showSizeAttributeEntrance
    },
    // 本地尺码切换依赖数据 ⬇️
    showLocalText() {
      return this.country && this.isMatched && !this.isOneSize
    },
    showLocalTextSelect() {
      const sizeRuleList = this.localSize?.size_rule_list || {}
      return JSON.stringify(sizeRuleList) !== '{}' && !this.isOneSize
    },
    // SizeTips ⬇️
    defaultTrueToSizeText() {
      return this.tipsCombo.defaultTrueToSizeText
    },
    showHeelHeight() {
      return this.tipsCombo.showHeelHeight
    },
    isShowSizeTipsText() {
      return (
        !this.defaultTrueToSizeText &&
        // this.isShowSizeTips?.isHitExperiment &&
        this.isShowSizeTips?.isShow
      )
    },
    trueToSize() {
      const { heelHeight, showHeelHeight } = this.tipsCombo || {}
      if (this.isShowSizeTipsText) {
        return `${this.isShowSizeTips?.sizeTipsText || ''}${
          showHeelHeight ? '; ' + heelHeight : ''
        }`
      }

      if (showHeelHeight) {
        return `${this.defaultTrueToSizeText}${
          this.constant.cssRight || !this.defaultTrueToSizeText ? ' ' : '; '
        }${heelHeight}`
      }

      return this.defaultTrueToSizeText
    },
    tipsShow() {
      const { showHeelHeight } = this.tipsCombo || {}
      return (
        this.trueToSize ||
        showHeelHeight ||
        this.isShowSizeTipsText ||
        this.isShowSizeTips?.showTrueToSizeStrong
      )
    },
    /**
     * 是否展示尺码图片信息，abt不满足或者不存在图片数据
     */
    isHasAttrPicInfo() {
      const {
        skcSaleAttr,
        config,
      } = this

      if (!config.isShowAttrPicInfo) return false
      return skcSaleAttr.some(d => d.attr_value_list?.some(d => !!d.attr_image))
    },
  },
  watch: {
    externalSizeInfoIndex(val) {
      val && this.initNeedSetSizeIndex()
    },
    externalSkuCode(val) {
      this.handleSkuCodeChange(val)
    },
    showHeelHeight() {
      // TODO yidier 由于现在旧数据没有合并，倒是跟高这里会有异步操作，暂时加这个监听绑定响应式
      this.lazyTipsShow = this.tipsShow
    },
    skuMapCompose () {
      this.setFoldSizes()
      if (!this.needUpdateSize) return
      this.onlyOneSizeCheck()
      if (this.emitSizeParam) this.sizeEmit(this.emitSizeParam)
    },
    goodsId(newVal, oldVal) {
      // 自动切换库存
      if (oldVal && newVal != oldVal && this.config?.openAutoCheckMall && !this.chooseCheck(-1, '', '', this.mallCode)) {
        const hasStockMall = this.baseInfo?.mall_info?.find((_) => !!this.chooseCheck(-1, '', '', _?.mall_code))
        const mallCode = hasStockMall?.mall_code || ''
        if (mallCode === '') return
        this.mallClick({ 
          mallCode,
          mallStock: this.chooseCheck(-1, '', '', mallCode)
        })
      }
    },
  },
  provide() {
    return {
      language: this.language,
      sizeRecommendType: this.sizeRecommendType,
      customerStyle: this.customerStyle,
      calcSize: this.calcSize,
      chooseCheck: this.chooseCheck,
      dataMap: {},
      dataMapOrder: this.dataMapOrder, // dataMap字符串的顺序
      config: this.config,
      constant: this.constant
    }
  },
  async mounted() {
    if(this.sizesReadyCallback) {
      this.sizesReadyCallback()
    }
    this.onlyOneSizeCheck()

    // 曝光 可否丢一个 worker 里
    // this.exposeSizeHandle()

    this.$nextTick(() => {
      this.initNeedSetSizeIndex()
      // 组件懒加载
      this.prefetchResources()
    })
  },
  methods: {
    handleSkuCodeChange(code) {
      if (code && code != this.skuInfo.sku_code) {
        this.defaultClick()
      }
    },
    exposeSizeHandle() {
      const { skcSaleAttr, showSoldOutTips } = this
      const saleAttr = skcSaleAttr
      let attrValue = saleAttr
        .map((arr) => {
          return arr?.attr_value_list?.map?.((_) => _.attr_value_name)?.join(',')
        })
        ?.join(',')

      if (!this.hideOneSizeModule) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-123',
          extraData: {
            count: saleAttr.length,
            attrvalue: attrValue,
            location: this.config?.from || 'page',
            isAttrFold: this.config?.isAttrFold,
          },
        })
      }

      if (showSoldOutTips) { // 售罄曝光
        daEventCenter.triggerNotice({
          daId: '1-6-1-4'
        })
      }
    },
    repeatUpdateSize() {
      this.sizeEmit({})
      this.$nextTick(() => {
        this.$refs['s-slide']?.calHeight?.()
      })
    },
    onlyOneSizeCheck() {
      if (this.onlyOneSize || this.hasOneOptionAttr) {
        this.defaultClick()
      }
    },
    async defaultClick() {
      if (this.isInit) {
        await this.commonSizeInit(true)
      }
      this.sizeEmit({})
      this.sizeTarget = this.sizeInfoIndex?.[this.bubbleInfo?.sizeInfoDesAttrId]?.attr_value_name
    },
    sizeTipsInitCb() {
      this.exposeSizeHandle()
      if (this.config?.showFeedBack && this.hasTargetCatId && this.feedBackBasicSizes?.length) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-54',
          extraData: {
            goods_id: this.goodsId,
          },
        })
      }
    },
    commonSizeTipsInit() {
      const { tipsCombo, constant, parentCats, language, skcSaleAttr, polyAttrSize } = this
      const {
        defaultTrueToSizeText,
        sizeTipsConfig,
        commentOverView,
        // isHitSizeTipsExperiment,
        showTrueToSizeStrongAbt, // TODO 这个要调整到 config 中
        isReady,
      } = tipsCombo

      const data = new SizeTipsCalculator({
        commentOverView,
        sizeTipsConfig,
        defaultTrueToSizeText,
        // isHitSizeTipsExperiment,
        isReady,
        constant,
        parentCats,
        language,
        showTrueToSizeStrongAbt,
        skcSaleAttr,
        polyAttrSize,
      }).getSizeTipsConfig()

      this.hasTargetCatId = data.hasTargetCatId
      this.isShowSizeTips = data.isShowSizeTips
      this.showHightSize = data.showHightSize
      this.feedBackBasicSizes = data.feedBackBasicSizes
      this.lazyTipsShow = this.tipsShow
      this.sizeTipsInitCb()
    },
    async commonSizeInit(isSetIndex) {
      const {
        sizeInfoDes,
        showMultiLayer,
        language,
        polyAttrSize,
        skcSaleAttr,
        tackInfoDes,
        sizeInfoIndex,
        skuList,
        localSizeRules,
        sizeInfoIndexBak,
        config
      } = this

      const initParams = {
        skcSaleAttr,
        localSizeRules,
        tackInfoDes,
        sizeInfoDes,
        language,
        skuList,
        sizeInfoIndex,
        showMultiLayer,
        polyAttrSize,
        sizepriority: config.sizepriority
      }

      if (!this.sizeCalculator) {
        this.sizeCalculator = new SizeCalculator(initParams)
      } else if (!isSetIndex && this.sizeCalculator) {
        this.sizeCalculator.init(initParams)
      }

      const data = this.sizeCalculator.getFormatSizeData({
        needSetSizeIndex: this.needSetSizeIndex,
        needSetSkuInfo: this.externalSkuCode, 
        sizeInfoIndexBak: sizeInfoIndexBak,
        sizeInfoIndex,
        chooseCheck: this.chooseCheck,
      })

      this.bubbleInfo = data.bubbleInfo
      this.isShowBaseCode = data.isShowBaseCode
      this.isMatched = data.isMatched
      // this.sizeInfoIndex = data.sizeInfoIndex
      
      this.sizeInfoIndexBak = data.sizeInfoIndexBak
      this.needSetSizeIndex = data.needSetSizeIndex

      await this.$nextTick()

      this.isInit = true
    },
    // scroll to size tips view
    sizeScrollView() {
      if (typeof window !== 'undefined') {
        this.$nextTick(() => {
          this.$refs['s-slide']?.calHeight && this.$refs['s-slide'].calHeight()
          if (!this.timer) clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            const win =
              this.config?.from == 'detailPage'
                ? document.documentElement
                : this.$el.closest('.S-drawer__body')
            const _addBagEl =
              this.config?.from == 'detailPage'
                ? document.querySelector('.product-intro__add-cart')
                : document.querySelector('.quick_add__btns')
            let addBagHeight = _addBagEl?.getBoundingClientRect?.().height
            let bodyHeight = win?.getBoundingClientRect?.().height
            let sizeRec = document
              .querySelector('.goods-size__slide-common')
              ?.getBoundingClientRect?.()
            if (!sizeRec) return
            let soldoutHeight =
              document.querySelector('.soldout-warning.goods-detial')?.getBoundingClientRect?.()
                ?.height || 0
            let distance = parseInt(
              sizeRec.top - (bodyHeight - addBagHeight - sizeRec.height - soldoutHeight - 50)
            )
            if (distance > 0) {
              win.scrollTo({
                top: win.scrollTop + distance,
                behavior: 'smooth',
              })
            }
          }, 200)
        })
      }
    },
    clickToBuyHandle() {
      this.$emit('clickToBuy')
    },
    openSizeGuide(scene = '', fromScene = '') {
      this.$emit('openSizeGuide', scene, fromScene)
    },
    /**
     * 快捷加车
     */
    handlerOpenAdd(item, index = 1) {
      if (this.config?.from == 'detailPage') {
        this.$quickAdd.open({
          goods_id: item.goods_id,
          isShowAttrPlusSize: false,
          addSource: 'detailPage',
          index,
          showBestDealLabel: true,
          showFollowBeltByOrigin: true,
          showEstimatedPrice: !this.config?.isHitComplianceMode,
          clickCallBack: {
            handleChangeLocalCountry: (value) => {
              // 数据联动控制
              this.config?.isNewSizeLocal && this.$emit('handleLocalChange', value)
            },
            // 加车成功后的回调
            complete: () => {
              setTimeout(() => {
                window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', { animation: false })
              }, 2000)
            },
          },
          analysisConfig: {
            code: 'goodsDetail',
            sa: {
              activity_from: 'more_size',
            },
            from: 'more_size',
          },
        })
      } else {
        const { langPath } = this.constant
        _gb_app_.$router.push(
          `${langPath}${getGoodsUrl(item.goods_url_name, item.goods_id, item.cat_id)}`
        )
      }
    },
    async sizeClick(item, subItem, flag) {
      if (!this.isInit) {
        await this.commonSizeInit()
      }
      if (this.chooseCheck(item.attr_id, item.attr_name, subItem.attr_value_name, '', subItem.attr_value_id) !== undefined) {
        if (this.sizeInfoIndex[item.attr_id].attr_value_id == subItem.attr_value_id) {
          this.$set(this.sizeInfoIndex, item.attr_id, {
            attr_name: item.attr_name,
            attr_value_name: '',
            attr_value_id: '',
          })
        } else {
          this.$set(this.sizeInfoIndex, item.attr_id, {
            attr_name: item.attr_name,
            ...subItem,
          })
        }
      } else {
        if (!this.config?.isNoSkuClick) return
        for (let attr_id in this.sizeInfoIndex) {
          if (attr_id == item.attr_id) {
            this.$set(this.sizeInfoIndex, attr_id, {
              attr_name: item.attr_name,
              ...subItem,
            })
          } else {
            this.$set(this.sizeInfoIndex, attr_id, {
              ...this.sizeInfoIndex[attr_id],
              attr_value_name: '',
              attr_value_id: '',
            })
          }
        }
      }

      this.sizeTarget = this.sizeInfoIndex?.[this.bubbleInfo?.sizeInfoDesAttrId]?.attr_value_name
      this.userSelectSize = flag

      this.emitSizeParam = {
        item: { attr_id: item.attr_id, ...subItem, }, 
        isUserSelect: this.userSelectSize
      }

      this.sizeEmit(this.emitSizeParam)
      if (this.showDesc) {
        this.sizeScrollView()
      }

      // TODO yidier
      // if (this.limitedGoodsIndex() > -1) {
      //   if (this.sizeLimit.length) {
      //     this.limitSizeOperation()
      //   } else {
      //     this.getLimitedData(this.limitSizeOperation)
      //   }
      // }
    },
    async sizeEmit({ item = {}, isUserSelect }) {
      if (!this.isInit) {
        await this.commonSizeInit()
      }
      if (!this.popCenter.SizeSlide) {
        this.popCenter.SizeSlide = true
      }
      let str = ''
      let idStr = ''
      let attrs = []
      let skuInfo = {}
      for (let i = 0; i < this.dataMapOrder.length; i++) {
        const id = this.dataMapOrder[i]
        const sizeInfo = this.sizeInfoIndex[id] || {}
        const { attr_value_name, attr_name, attr_value_id } = sizeInfo
        attrs.push({ attr_id: id, ...sizeInfo })
        if (attr_value_name) {
          str += attr_name + '__' + attr_value_name + ','
          idStr += attr_name + '__' + attr_value_id + ','
        } else {
          str += ','
          idStr += ','
        }
      }
      // 检测最新选择的销售属性和当前的mall能否组成一个正确的商品
      const mallStock = this.openIdMap ? this.mallCheck(idStr, true) : this.mallCheck(str)
      if (isUserSelect) {
        // 用户的最后一次选择备份一下
        this.sizeInfoIndexBak = JSON.stringify(this.sizeInfoIndex)
      }
      if (this.skuList.length == 1 && this.skcSaleAttr.length == 0) {
        skuInfo = { ...this.skuList[0], skc_name: this.saleAttrList.skcName }
      }
      if (this.skuMap?.[str]) {
        skuInfo = {
          ...this.skuMap?.[str],
          skc_name: this.saleAttrList.skcName,
        }
      }
      if (this.openIdMap && this.skuIdMap?.[idStr]) {
        skuInfo = {
          ...this.skuIdMap[idStr],
          skc_name: this.saleAttrList.skcName,
        }
      }
      skuInfo?.sku_sale_attr?.forEach?.((item) => {
        if (typeof item !== 'object') return
        // 最终显示在页面的属性name
        item.sku_calc_name = this.calcSize(item) || ''
      })

      this.skuInfo = this.getRealTimeSkuInfo(skuInfo)
      
      this.$emit(
        'compose',
        JSON.parse(
          JSON.stringify({
            attrs,
            skuInfo,
            mallStock,
            externalSizeInfoIndex: this.sizeInfoIndex,
            curSelectAttr: item,
            isUserSelect,
            isFirst: this.firstCompose,
          })
        )
      )
      this.firstCompose = false
    },
    /**
     * 获取实时 sku 信息
     */
    getRealTimeSkuInfo(skuInfo) {
      const { sku_code } = skuInfo
      const realTimeData = this.skuAllInfo?.[sku_code] || {}
      let mall = {}
      if (realTimeData.mall_price?.length) {
        Object.keys(skuInfo.mall).map(mallCode => {
          let realMallPrice = realTimeData?.mall_price?.find?.(item => item.mall_code == mallCode)
          mall[mallCode] = Object.assign(skuInfo.mall[mallCode], realMallPrice, { promotionInfo: realTimeData.promotionInfo })
        })
      }
      return Object.assign(
        skuInfo,
        realTimeData,
        realTimeData.mall_price?.length ? { mall } : {}
      )
    },
    mallCheck(str, isIdMap = false) {
      // 没有这种属性项+mall的组合
      const mallList = this.baseInfo?.mall_info || []
      if (this.chooseCheck(-1, '', '', this.mallCode) === undefined) {
        const optionalMallCode = {}
        let hasStockMall = false
        let mallCode = ''
        // 提取有库存的并且可组合的mallCode
        const targetMapData = isIdMap ? this.dataIdMap : this.dataMap
        for (let k in targetMapData) {
          if (k && k.indexOf(str) > -1) {
            const attr = k.split(',')
            if (targetMapData[k] > 0) {
              hasStockMall = true
            }
            optionalMallCode[attr[attr.length - 1] || ''] = targetMapData[k]
          }
        }
        // mallList的优先级是降序的
        for (let i = 0; i < mallList.length; i++) {
          const mall_code = mallList[i].mall_code
          // 优先找有库存的mall
          if (hasStockMall) {
            if (optionalMallCode[mall_code]) {
              mallCode = mall_code
              break
            }
          } else {
            // 所有mall都没库存，选取优先级最高的那个mall
            if (optionalMallCode[mall_code] == 0) {
              mallCode = mall_code
              break
            }
          }
        }
        if (typeof window !== 'undefined' && this.mallCode) {
          this.$toast(
            this.template(
              this.baseInfo.mall[this.mallCode].mall_name,
              this.language.SHEIN_KEY_PWA_21064
            ),
            1500
          )
        }
        const mallStock = this.chooseCheck(-1, '', '', mallCode)
        this.mallClick({
          mallCode,
          mallStock,
        })
        return mallStock
      }
      return this.chooseCheck(-1, '', '', this.mallCode)
    },
    calcSize(label) {
      const { attr_std_value, attr_value_name } = label
      const { defaultlocalsize } = this.config
      // 默认尺码
      let defaultSize = attr_std_value ? `${attr_std_value} / ${attr_value_name}` : attr_value_name
      // 本地尺码，选择查看默认尺码时，本地尺码不存在 (所以当本地尺码存在时，必定为主尺码)
      let localSize = this.localSizeMap?.[attr_value_name] || ''
      let { main_size, sub_size } = sortMainSubSize(defaultSize, localSize, defaultlocalsize)

      // 如果不存在副尺码，且需要显示高度尺码，且尺码不包含/，且存在高度尺码
      if (sub_size === '' &&
        this.showHightSize &&
        main_size.indexOf('/') === -1 &&
        this.heightSizeMap[this.sizeUnit]?.[label.attr_value_name]
      ) {
        sub_size = `${this.heightSizeMap[this.sizeUnit]?.[attr_value_name]}`
      }
      return sub_size ? `${main_size} ${sub_size}` : main_size
    },
    chooseCheck(attrId, attrName, attrValueName, mallCode, attrValueId = '') {
      // if (!this.dataMapOrder?.length) return true
      let str = ''
      let idStr = ''
      for (let i = 0; i < this.dataMapOrder.length; i++) {
        const id = this.dataMapOrder[i]
        if (id == attrId) {
          str += attrName + '__' + attrValueName + ','
          idStr += attrName + '__' + attrValueId + ','
          continue
        }
        const { attr_name, attr_value_name, attr_value_id } = this.sizeInfoIndex?.[id] || {}
        if (attr_value_name) {
          str += attr_name + '__' + attr_value_name + ','
          idStr += attr_name + '__' + attr_value_id + ','
        } else {
          str += ','
          idStr += ','
        }
      }
      if (mallCode) {
        str += mallCode
        idStr += mallCode
      }

      if (this.openIdMap) {
        return this.dataIdMap?.[idStr]
      }

      return this.dataMap[str]
    },
    formatHeightSize(h, type) {
      if (!h.length) return ''
      try {
        let numberStr = h.replace(/(cm|inch)/g, '').trim()
        let numberArr = numberStr.split('-')
        if (isNaN(numberArr[0])) return ''
        if (numberArr.length === 2) {
          // 104.3-110.3 cm
          return `(${Number(numberArr[0]).toFixed()}-${Number(numberArr[1]).toFixed()}${type})`
        } else {
          // 104.3cm
          let singleNumber = numberArr[0].split(type)
          return `(${Number(singleNumber[0]).toFixed()}${type})`
        }
      } catch (error) {
        return ''
      }
    },
    changeTips(stock) {
      return template(stock, this.language.SHEIN_KEY_PWA_16814)
    },
    onSizeFeedbackBtnClick() {
      if (!this.popCenter.isLoad) {
        // 初始化的时候赋值
        this.feedBackCountry = this.country
        this.popCenter.isLoad = true
        this.updatePopCenter('FeedBack')
      } else {
        this.$refs?.['SIZE_FEED_BACK']?.onSizeFeedbackBtnClick()
      }
      daEventCenter.triggerNotice({
        daId: '1-6-1-53',
        extraData: {
          goods_id: this.goodsId,
        },
      })
    },
    async clickLocalTextSelectHandle() {
      if (!this.popCenter['LocalSize']) {
        const res = await import('public/src/pages/components/product/select-local-size/index.js')
        this.SelectLocalSize = res.default
        this.popCenter['LocalSize'] = true
      }

      this.SelectLocalSize.open({
        id: this.goodsId, 
        value: this.feedBackLocalCountry,
        localSize: this.localSize,
        options: {
          config: {
            appendToBody: true,
            isNewSizeLocal: this.config?.isNewSizeLocal
          },
          callbacks: {
            change: country => {
              this.feedBackCountry = country
              this.sizeLocalChange(country)
            }
          }
        }
      })

      daEventCenter.triggerNotice({
        daId: '1-6-1-105',
        extraData: {
          choose_type: !this.country ? 1 : 0,
        },
      })
    },
    updatePopCenter(key) {
      this.popCenter.isLoad = true
      this.popCenter[key] = true
    },
    sizeLocalChange(val) {
      this.$emit('handleLocalChange', val)
      daEventCenter.triggerNotice({
        daId: '1-6-1-106',
        extraData: {
          choose_type: val,
        },
      })
      this.$refs?.['SIZE_FEED_BACK'] && this.$refs?.['SIZE_FEED_BACK'].updateFeedBackList?.()
    },
    // showLocalSizeHandle(val) {
    //   this.showLocalSize = val
    // },
    handleUpdateQuickShip(status) {
      this.$emit('updateQuickShip', status)
    },
    openSizeGuideFromSizeTips(fromScene = '') {
      if (this.isShowSizeTipsText) {
        this.openSizeGuide(undefined, fromScene)
        daEventCenter.triggerNotice({
          daId: '1-6-1-114',
          extraData: {
            type: this.isShowSizeTips.isLargeSizeTips ? 1 : 2,
          },
        })
      }
    },
    openReviews() {
      location.hash += 'comment-all'
      daEventCenter.triggerNotice({
        daId: '1-6-1-114',
        extraData: {
          type: 3,
        },
      })
    },
    mallClick(mallInfo) {
      // 待定要不要执行init
      this.$emit('mallClick', mallInfo)
    },
    handleSizeRecommendType(sizeRecommendType) {
      this.sizeRecommendType = sizeRecommendType
    },
    selfSizeRecommendClick() {
      this.$emit('handleSizeRecommend', true)
    },
    /**
     * 新商品需要加这个
     */
    async resetSizeBox(isNewGoods, isNewSpu) {

      const { recommendCombo, onlyOneSize } = this
      const { recommendMySize = '', recommendMyBraSize = '' } = recommendCombo

      this.isInit = false
      if (isNewGoods) {
        if (isNewSpu) {
          this.sizeInfoIndexBak = '{}'
          this.sizeInfoIndex = {}
        }
        this.onlyOneSizeCheck()
      }
      this.commonSizeTipsInit()
      if (!onlyOneSize) {
        this.sizeEmit({})
        this.sizeTarget = this.sizeInfoIndex?.[this.bubbleInfo?.sizeInfoDesAttrId]?.attr_value_name
      }
      this.setFoldSizes()


      this.$nextTick(() => {
        if (recommendMySize) {
          this.sizeValue(recommendMySize)
        }

        if (recommendMyBraSize) {
          this.sizeValue(recommendMyBraSize)
        }

        // this.exposeSizeHandle()
      })
    },
    async sizeValue(size) {
      if (!this.isInit) {
        await this.commonSizeInit()
      }
      for (let i = 0; i < this.polyAttrSize.length; i++) {
        if (
          this.polyAttrSize[i].attr_value_name === size &&
          this.chooseCheck(
            87,
            this.sizeInfoIndex[87].attr_name,
            this.polyAttrSize[i].attr_value_name,
            '',
            this.polyAttrSize[i].attr_value_id,
          ) > 0
        ) {
          const item = this.skcSaleAttr[this.skcSaleAttr.length - 1]
          if (item.attr_id == 87) {
            return this.sizeClick(item, this.polyAttrSize[i])
          }
        }
      }
    },
    setFoldSizes: debounce({
      func: function() {
        if (this.config.from === 'popup') return
        if (this.config?.sizeUnFold) return
        let containerDoms = document.getElementsByClassName('SIZE_ITEM_HOOK')
        if (!containerDoms || !containerDoms.length) return
        this.foldBtn = this.skcSaleAttr.map(() => false)
        let getTop = (dom) => dom.getBoundingClientRect().top
        for (let i of Object.keys(containerDoms)) {
          let containerDom = containerDoms[i]
          let sizeItemDoms = containerDom.children
          if (!sizeItemDoms || !sizeItemDoms.length) continue
          let containerDomTop = getTop(containerDom)
          let sizeItemTops = []
          for (let i of sizeItemDoms) {
            let temp = getTop(i)
            if (!sizeItemTops.includes(temp)) {
              sizeItemTops.push(temp)
            }
          }
          if (sizeItemTops.length < 5) {
            containerDom.style.height = ''
          } else {
            containerDom.style.height = `${sizeItemTops[4] - containerDomTop}px`
            this.foldIndex = i
            this.$set(this.foldBtn, i, true)
            daEventCenter.triggerNotice({
              daId: '1-6-1-156',
            })
          }
          
          break
        }
        // 设置 viewMoew 下间距
        // 1. 存在2个 size 列表并且第一个列表需要折叠时，需要设置间距
        // 2. 在商详时存在 Quantity 数量控制时需要设置间距
        if (this.skcSaleAttr.length === 2) {
          if (this.foldBtn.findIndex((item) => item) === 0) {
            this.showSizeMoreHolder = true
          } else {
            if ($('.goods-qty').length && this.config.from !== 'popup') {
              this.showSizeMoreHolder = true
            }
          }
        } else {
          if ($('.goods-qty').length && this.config.from !== 'popup') {
            this.showSizeMoreHolder = true
          }
        }
      },
      wait: 100
    }),
    setFoldShow(index) {
      if (this.foldBtn.every((item) => !item)) return true
      if (this.foldIndex < 0) {
        return true
      } else {
        return this.foldIndex >= index ? true : false
      }
    },
    handleClickSizeGroupItem(item) {
      this.$emit('handleClickSizeGroupItem', item)
    },
    handlePerfectFitShowStatus(status) {
      this.$emit('handlePerfectFitShowStatus', status)
    },
    handleOpenFold(index) {
      let containerDoms = document.getElementsByClassName('SIZE_ITEM_HOOK')
      this.$set(this.foldBtn, index, false)
      containerDoms[index].style.height = 'auto'
      daEventCenter.triggerNotice({
        daId: '1-6-1-157',
      })
    },
    prefetchResources() {
      if (this.isLazyLoaded) return
      this.isLazyLoaded = true
      // el 必须是渲染完的
      prefetchResource.listen({
        el: this.$el,
        prefetchList: [
          {
            chunkName: 'RecommendEntrance',
            relType: 'prefetch',
          },
          {
            chunkName: 'QuickShip',
            relType: 'prefetch',
          },
        ],
        prefetchCallback: () => {
          this.commonSizeTipsInit()
        },
        delay: 2000,
      })
    },
    initNeedSetSizeIndex() {
      const { externalSizeInfoIndex } = this
      if (
        Object.keys(externalSizeInfoIndex).length > 0 &&
        JSON.stringify(externalSizeInfoIndex || {}) != JSON.stringify(this.sizeInfoIndex)
      ) {
        this.needSetSizeIndex = externalSizeInfoIndex
        this.defaultClick()
      }
    },
    /**
     * 尺码推荐延迟
     */
    sizeRecommendContent(attrId) {
      const { handleSizeRecommendType, selfSizeRecommendClick, openSizeGuide, isLazyLoaded, sizeGuidPlanA } = this
      if (!isLazyLoaded) return null
      const {
        config,
        baseInfo,
        localSize,
        recommendCombo,
        language,
        localSizeRules,
        saleAttrList,
        country,
      } = this
      const currentGoodsId = baseInfo?.goods_id
      const recommendParams = {
        ...recommendCombo,
        sizeRuleList: localSize?.size_rule_list || {},
      }
      const { recommendMySize = '', recommendMyBraSize = '', ruleId, ruleType } = recommendParams
      if (attrId == 87 && config?.showSizeRecommend) {
        return (
          <RecommendEntrance
            sizeGuidPlanA={sizeGuidPlanA}
            current-goods-id={currentGoodsId}
            recommend-my-size={recommendMySize}
            language={language}
            local-size-rule={localSizeRules}
            rule-id={ruleId}
            rule-type={ruleType}
            recommend-my-bra-size={recommendMyBraSize}
            sale-attr-list={saleAttrList}
            country={country}
            show-new-size-guide={config?.showNewSizeGuide}
            isFromPopup={config?.from === 'popup'}
            is-new-size-local={config?.isNewSizeLocal}
            v-on:handleSizeRecommendType={handleSizeRecommendType}
            v-on:handleSizeRecommend={selfSizeRecommendClick}
            v-on:handleSizeGuide={openSizeGuide}
          />
        )
      } else {
        return null
      }
    },
    /**
     * quickShip 延迟引入
     */
    quickShipContent() {
      const { handleUpdateQuickShip, tipsCombo, localSizeMap } = this
      const { constant, config, mallCode, skuInfo, skuList, skcSaleAttr, language, isLazyLoaded } =
        this
      const { showQuickShip, from, isNewSizeLocal } = config
      const { deliveryFloorQuickshipTitle = '' } = tipsCombo
      if (!isLazyLoaded) return null
      if (showQuickShip) {
        return (
          <QuickShip
            from={from}
            mall-code={mallCode}
            sku-info={skuInfo}
            sku-list={skuList}
            skc-sale-attr={skcSaleAttr}
            local-size-map={localSizeMap}
            css-right={constant?.cssRight}
            language={language}
            is-new-size-local={isNewSizeLocal}
            delivery-floor-quickship-title={deliveryFloorQuickshipTitle}
            v-on:updateQuickShip={handleUpdateQuickShip}
          />
        )
      } else {
        return null
      }
    },
    getAttrPicInfoMap() {
      const {
        skcSaleAttr,
        sizeInfoIndex,
        showSoldOutTips,
        language,
        soldOutTips: _soldOutTips,
        goodsId,
        config,
        isHasAttrPicInfo,
      } = this

      if (!isHasAttrPicInfo || !sizeInfoIndex) return null

      const soldOutTips = showSoldOutTips && _soldOutTips || ''
      return Object.keys(sizeInfoIndex).reduce((map, attr_id) => {
        if (!sizeInfoIndex[attr_id]?.attr_image) return map

        const _index = skcSaleAttr.findIndex(d => d.attr_id == attr_id)

        const attr_value = sizeInfoIndex[attr_id] && this.calcSize(sizeInfoIndex[attr_id]) || ''
        map[attr_id] = {
          textDetail: `${language.SHEIN_KEY_PWA_24732}: `,
          attr_value,
          attr_image: sizeInfoIndex[attr_id]?.attr_image,
          soldOutTips,
          analysisData: {
            // 弹窗 popup 页面page
            location: config.from === 'detailPage' ? 'page' : 'popup',
            goods_id: goodsId,
            main_attr: _index !== -1 ? _index + 2 : undefined,
          }
        }
        return map
      }, {})
    },
    checkSelectSize() {
      const unSelectedAttrs = []
      if (this.active) {
        return { status: true }
      }
      for (let i = 0; i < this.skcSaleAttr.length; i++) {
        const item = this.skcSaleAttr[i]
        if (!this.sizeInfoIndex[item.attr_id]?.attr_value_name) {
          unSelectedAttrs.push(item.attr_name)
        }
      }
      return { status: false, unSelectedAttrs }
    }
  },
  render() {
    // function
    const {
      active,
      clickToBuyHandle,
      sizeClick,
      handlerOpenAdd,
      openSizeGuide,
      clickLocalTextSelectHandle,
      chooseCheck,
      mallClick,
      openSizeGuideFromSizeTips,
      openReviews,
      setFoldShow,
      handleOpenFold,
      onSizeFeedbackBtnClick,
      sizeRecommendContent,
      quickShipContent,
    } = this

    const {
      language,
      goodsId,
      country,
      config,
      constant,
      clickToBuyText,
      skcSaleAttr,
      relatedLocalSize,
      relatedSizeMap,
      sizeInfoIndex,
      polyAttrSize,
      sizeInfoDes,
      showMultiLayer,
      hasTargetCatId,
      baseInfo,
      isOnSale,
      foldBtn,
      showSizeMoreHolder,

      // mall
      mallCode,
      mallInfo,
      currentSkuSoldOut,

      // slide
      showDesc,
      soldOutTips,
      sizeStockTips,
      sizeInfoStr = [],
      sizeInfoTitle,
      showSlideSizeGuide,
      showSizeAttributeEntrance,

      // hurry
      showSoldOutTips,

      // tips
      tipsCombo,
      trueToSize,
      isShowSizeTips,
      isShowSizeTipsText,
      lazyTipsShow,

      // Local Size
      showLocalTextSelect,
      showLocalText,
      localSize,
      localSizeRules,
      popCenter,

      // SizeFeed
      feedBackBasicSizes,
      feedBackLocalCountry,
    } = this
    const { showMallSite, from, isNewSizeLocal, showFeedBack } = config

    const showFeedBackStatus = showFeedBack && hasTargetCatId && feedBackBasicSizes.length
    const { relateGood, relateGoodType } = relatedLocalSize
    const relatedParams = { relateGood, relateGoodType, relatedSizeMap }

    const slideTipsParams = {
      showDesc,
      soldOutTips,
      sizeStockTips,
      sizeInfoStr,
      sizeInfoTitle,
      showSlideSizeGuide,
      showSizeAttributeEntrance
    }

    const localSelectParams = {
      showLocalTextSelect,
      showLocalText,
      country,
      localSizeRules,
    }

    const { bold, title } = this.customerStyle

    const attrPicInfoMap = showMultiLayer && this.getAttrPicInfoMap()
    const _lastItem = skcSaleAttr?.length && skcSaleAttr[skcSaleAttr.length - 1] || {}
    const _lastItemHasPicInfo = attrPicInfoMap?.[_lastItem.attr_id]

    const mallContent = () =>
      showMallSite && mallInfo.length ? (
        <Mall
          mall-list={mallInfo}
          language={language}
          mall-code={mallCode}
          title-bold={bold}
          is-on-sale={isOnSale}
          choose-check={chooseCheck}
          from={from}
          config={config}
          constant={constant}
          current-disable={currentSkuSoldOut}
          v-on:mallClick={mallClick}
        />
      ) : null

    const hurrySoldTips = () =>
      !_lastItemHasPicInfo && showSoldOutTips ? (
        <span
          aria-hidden="true"
          class="goods-size__item-tips"
        >
          {soldOutTips}
        </span>
      ) : null

    const tipsShowContent = () =>
      lazyTipsShow ? (
        <SizeTipsContent
          tips-combo={tipsCombo}
          true-to-size={trueToSize}
          is-show-size-tips={isShowSizeTips}
          isShowSizeTipsText={isShowSizeTipsText}
          noJump={config.from === 'popup'}
          isFromPopup={config?.from === 'popup'}
          v-on:openSizeGuideFromSizeTips={openSizeGuideFromSizeTips}
          v-on:openReviews={openReviews}
          
        />
      ) : null
    const sizeFeedBackContent = () =>
      showFeedBackStatus ? (
        <SizeFeedBackEnter v-on:onSizeFeedbackBtnClick={onSizeFeedbackBtnClick} />
      ) : null

    const sizeSlideTipsContent = () =>
      // 图片预览优先级高于尺码气泡
      !_lastItemHasPicInfo && !this.hideOneSizeModule && popCenter.SizeSlide ? (
        <SizeSlideTips
          ref="SLIDE-TIPS"
          ref-in-for={true}
          async-content-ready={popCenter.SizeSlide}
          slide-tips-params={slideTipsParams}
          isFromPopup={config?.from === 'popup'}
          v-on:openSizeGuide={openSizeGuide}
        />
      ) : null

    const SizeGroupContent = (<SizeGroup
      language={language}
      from="detail"
      goods-id={goodsId}
      ccc-attr-groups={this.cccAttrGroups}
      ccc-only-related-goods={this.cccOnlyRelatedGoods}
      global-perfect-fit-show={config.globalPerfectFitShow}
      find-perfect-fit-scene={this.sizeGroupPosition == 'fold'}
      v-on:handleClickSizeGroupItem={this.handleClickSizeGroupItem}
      v-on:handlePerfectFitShowStatus={this.handlePerfectFitShowStatus}
    />)



    const contentEl = () => 
      showMultiLayer ? (
        <div>
          {this.sizeGroupPosition === 'mid' ? SizeGroupContent : null}
          <div v-show={!this.hideOneSizeModule}>
            <SizeSelectList
              config={config}
              size-group-position={this.sizeGroupPosition}
              related-params={relatedParams}
              size-info-index={sizeInfoIndex}
              skc-sale-attr={skcSaleAttr}
              local-select-params={localSelectParams}
              set-fold-show={setFoldShow}
              fold-btn={foldBtn}
              show-size-more-holder={showSizeMoreHolder}
              size-recommend-content={sizeRecommendContent}
              is-one-size={this.isOneSize}
              is-on-sale={isOnSale}
              attr-pic-info-map={attrPicInfoMap}
              v-on:handleOpenFold={handleOpenFold}
              v-on:sizeClick={sizeClick}
              v-on:handlerOpenAdd={handlerOpenAdd}
              v-on:clickLocalTextSelect={clickLocalTextSelectHandle}
              v-on:openSizeGuide={openSizeGuide}
            />
          </div>
          {sizeSlideTipsContent()}
          {this.sizeGroupPosition === 'bottom' ? SizeGroupContent : null}
          {hurrySoldTips()}
          {this.sizeGroupPosition === 'fold' ? SizeGroupContent : null}
          {tipsShowContent()}
          {sizeFeedBackContent()}
          {mallContent()}
          {quickShipContent()}
        </div>
      ) : (
        <div v-show={!this.hideOneSizeModule}>
          <SizeClickToBuy
            bold={bold}
            title={title}
            active={active}
            value={clickToBuyText}
            v-on:clickToBuy={clickToBuyHandle}
          />
        </div>
      )

    // 弹窗集合
    const popContent = () =>
      popCenter.isLoad ? (
        <ClientOnly>
          <SizeFeedBack
            ref="SIZE_FEED_BACK"
            language={language}
            constant={constant}
            base-info={baseInfo}
            local-size={localSize}
            size-info-des={sizeInfoDes}
            current-size-list={polyAttrSize}
            feed-back-basic-sizes={feedBackBasicSizes}
            skc-sale-attr={skcSaleAttr}
            is-new-size-local={isNewSizeLocal}
            country={feedBackLocalCountry}
            async-content-ready={popCenter.FeedBack}
            v-on:localClick={clickLocalTextSelectHandle}
            defaultlocalsize={config.defaultlocalsize}
          />
        </ClientOnly>
      ) : null

    return (
      <div class="goods-size">
        {contentEl()}
        {popContent()}
      </div>
    )
  },
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.goods-size {

  // padding-bottom: 0.106667rem;
  &__title {
    .flexbox();
    .align-center();
    .space-between();
    height: 0.43rem;
    line-height: 0.43rem;
  }

  .self-size-recommend #fitanalytics__button {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */
  }

  &__title-txt {
    text-transform: capitalize;
    font-style: normal;
    .font-dpr(24px);
  }

  &__title-bold {
    font-weight: 700;
    .font-dpr(28px);

    /* rw:begin */
    font-family: Adieu;
  }

  &__title-local {
    padding: 4/75rem 12/75rem;
    .font-dpr(24px);
    .text-overflow();
    border-radius: 40/75rem;
    background-color: @sui_color_gray_weak2;
    margin-left: 0.106rem;
  }

  &__wrapper {
    padding-bottom: 0.01rem;
  }

  &__sizes {
    min-height: 0.96rem;
    margin-top: 0.21333rem;
    overflow: hidden;
    .flexbox();
    .align-center();
    flex-flow: row wrap;

    &.on-line__style {
      width: 100%;
      display: inline-flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
    }

    &.on-line__style::-webkit-scrollbar {
      height: 0;
    }
  }

  &__relate-good {

    span,
    .icon-price_px_- {
      /* stylelint-disable-line */
      display: inline-block;
    }

    span+span {
      /* stylelint-disable-line */
      margin-left: 3px
        /*rtl:ignore*/
      ;
    }

    span:first-child {
      margin-left: 0
        /*rtl:ignore*/
      ;
    }

    .icon-price_px_- {
      /* stylelint-disable-line */
      vertical-align: sub;
      line-height: 1px;
    }

    .to-right {
      transform: rotate(-90deg) translate(-2px, 8px);
      transform-origin: top left;
    }
  }

  &__sizes-item {
    display: inline-block;
    max-width: 100%;
    padding: 0.16rem 0.333333rem 0.1333rem 0.333333rem;
    .margin-r(0.32rem);
    margin-bottom: 0.213333rem;
    .border-dpr(border, 2px, #e5e5e5);
    border-radius: 1.28rem;
    line-height: 0.4533rem;
    .font-dpr(28px);

    &.size-active {
      .border-dpr(border, 2px, #222);
      color: #222;
      /* rw:begin */
      .border-dpr(border, 2px, #fc4070);
      color: #fc4070;
    }

    &.sold-out {
      .border-dpr-style(border, 2px, dashed, #e5e5e5);
      color: #bbb;
    }

    &.size-sold-out {
      .border-dpr(border, 2px, #222);
      /* rw:begin */
      .border-dpr(border, 2px, #ff696e);
    }

    &.size-disabled {
      background: #fafafa;
      .border-dpr-style(border, 2px, dashed, #e5e5e5);
      color: #bbb;
    }

    &.twoline-text-box {
      line-height: 0;

      & > .goods-size__sizes-item-text--two {
        display: -webkit-inline-box;
        line-height: 0.4533rem;
      }
    }

    .goods-size__sizes-item-text {
      max-width: 8.64rem;
    }

    .goods-size__sizes-item-text--one {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
    }

    .goods-size__sizes-item-text--two {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__sizes-more {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    color: @sui_color_gray_dark3;
  }

  &__sizes-more-holder {
    height: 0.32rem;
  }

  &__slide-common {
    margin-bottom: 0.23rem;
  }

  &__item-info {
    .txt-l();
    background: #fafafa;
    padding: 0.11rem 0.21rem;
    .font-dpr(24px);
    padding: 10px;
    color: #666;

    >span {
      font-weight: bold;
    }

    .tips {
      color: @sui_color_highlight;
      font-weight: 400;
    }

    .mesurement-title {
      color: #959595;
    }

    .morethan3 {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .lessthan3 {
      .line-camp(2);
    }

    .sizeinfo-name {
      color: #222;
      font-weight: bold;
    }

    .sizeinfo-one {
      word-break: break-all;
      .margin-r(0.2rem);

      &::after {
        content: ',';
      }
    }

    .sizeinfo-one:last-of-type {
      &::after {
        content: '';
      }
    }

    .sizeinfo-t {
      color: #222;
    }
  }

  &__item-tips {
    display: inline-block;
    color: @sui_color_highlight;
    margin-bottom: 0.16rem;
  }
  &__tips-bold {
    font-weight: bold;
    color: #000000;
  }
  &__undersize-deviation-tips {
    display: -webkit-box;
    color: #767676;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0.16rem;
    word-break: break-all;
  }

  &__undersize-deviation-tips-icon {
    font-size: 0.24rem;

    &::before {
      position: relative;
      top: 2px;
    }
  }

  &__undersize-deviation-tipsstrong {
    .tipsstrong-span {
      color: @sui_color_micro_emphasis;
    }

    .like-icon {
      margin-right: 0.0533rem;
    }

    .more-icon {
      vertical-align: middle;
    }

    &.new-line {
      display: block;
    }
  }

  .size-feedback {
    &_title {
      color: #666;
      line-height: 1;

      span {
        color: @sui_color_link;
        cursor: pointer;
      }
    }
  }

  .capsule-box {
    unicode-bidi: normal
      /* rtl:bidi-override */
    ;
  }

  .capsule {
    .font-dpr(24px);
    margin-left: 0.16rem;
    vertical-align: sub;
  }
}

.goodsd-sizes {
  &__more {
    margin-top: 0.213333rem;
    padding-top: 0.213333rem;
    text-align: right;
    font-size: 0.32rem;
    color: #767676;
    border-top: 1px solid #e5e5e5;
  }
}

.fitanalytics__button {
  order: 3 !important;
  /* stylelint-disable-line declaration-no-important */
}

.fitanalytics__button-text {
  /* stylelint-disable-line */
  .font-dpr(24px) !important;
  /* rw:begin */
  color: #ff9999 !important;
  /* stylelint-disable-line declaration-no-important */
}</style>
